import React, {useState} from 'react'
import {Link, navigate} from "gatsby"
import {Col, Container, Row} from "react-bootstrap";
import Select from "react-select";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import Loader from "./Loader";
import './PayPalExtraPayment.scss'

const PayPalExtraPayment = () => {
  const paypalExtraOptions = [
    {value: 25, label: '1 Extra Session ($25)'},
    {value: 50, label: '2 Extra Sessions ($50)'},
    {value: 100, label: '4 Extra Sessions ($100)'},
    {value: 150, label: '6 Extra Sessions ($150)'},
  ]
  const [oneExtra] = paypalExtraOptions
  const [selectedOption, setSelectedOption] = useState(oneExtra)
  const handleChange = (option) => {
    setSelectedOption(option)
  };

  const [paypalExtraLoaded, setPaypalExtraLoaded] = useState(false)
  const paypalExtra = {
    options: {
      "data-sdk-integration-source": "button-factory",
      "data-namespace": "paypal-extra-sessions",
      "client-id": "AbLpoqfCm62ieA0V5DDKZX7yLHoRnk4UkvbjzNFDvtFGfDUNW8DqDqCgRQi2-MzEAfxifRMeeUfjE9sJ",
      "enable-funding": "venmo",
      "currency": "USD",
    },
    style: {
      layout: "vertical",
      color: "silver",
      label: "pay",
      shape: "pill"
    },

    onInit: () => {
      setPaypalExtraLoaded(true)
    },

    createOrder: (data, actions) => {
      return actions.order.create({
        purchase_units: [{
          description: selectedOption.label,
          amount: {
            currency_code: 'USD',
            value: selectedOption.value,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: selectedOption.value,
              },
              shipping: {
                currency_code: 'USD',
                value: 0,
              },
              tax_total: {
                currency_code: 'USD',
                value: 0,
              }
            }
          },
          items: [
            {
              name: selectedOption.label,
              unit_amount: {
                currency_code: 'USD',
                value: selectedOption.value,
              },
              quantity: 1
            }
          ]
        }]
      });
    },

    onApprove: async (data, actions) => {
      return actions.order.capture().then(function (orderData) {
        navigate("/thankyou", {
          state: {
            method: "paypal-many"
          }
        })
      });
    },

    onError: function (err) {
      console.log(err);
    }
  }

  return (
    <div id='paypal-extra' className='PayPalExtraPayment'>
      <Loader loading={!paypalExtraLoaded}/>
      <Container>
        <Row className='text-center PayPalExtraPayment__label'>
          <Col xs={12}>
            <h2 className='PayPalExtraPayment__label__title'>
              PayPal Extra Session
            </h2>

            <h5 className='PayPalExtraPayment__label__description'>
              In case you only want to pay as you go, or if you
              need more than 2 sessions / week, you can
              always opt for extra sessions for the same price.
              For monthly subscription packages check out the <Link to='/pricing'>pricing</Link> page.
            </h5>
          </Col>
        </Row>

        <Row className='PayPalExtraPayment__buttons'>
          <Col xs={12}>
            <PayPalScriptProvider options={paypalExtra.options}>
              <div style={{display: paypalExtraLoaded ? 'block' : 'none'}}>
                <Select
                  className='PayPalExtraPayment__SelectBox'
                  classNamePrefix='PayPalExtraPayment__SelectBox'
                  defaultValue={paypalExtraOptions[0]}
                  options={paypalExtraOptions}
                  onChange={handleChange}
                />

                <PayPalButtons
                  forceReRender={[selectedOption]}
                  className='d-flex justify-content-center'
                  style={paypalExtra.style}
                  createOrder={paypalExtra.createOrder}
                  onApprove={paypalExtra.onApprove}
                  onInit={paypalExtra.onInit}
                />
              </div>
            </PayPalScriptProvider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PayPalExtraPayment
