import React from 'react'
import './ThankYou.scss'

const ThankYou = () => {
  return (
    <div className='ThankYou'>
      <h1 className='ThankYou__title'>
        I am really grateful for your purchase
      </h1>

      <span className='icon-heart ThankYou__icon'/>
    </div>
  )
}

export default ThankYou
