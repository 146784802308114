import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {Accordion} from "react-bootstrap";
import {DelimitedNumericArrayParam, useQueryParam} from "use-query-params";
import './Faq.scss'

const Faq = ({data}) => {
  const [isClient, setClient] = useState(false)
  const [activeKeys, setActiveKeys] = useQueryParam("q", DelimitedNumericArrayParam);

  const render = (active) => {
    return (
      <Accordion alwaysOpen className='FAQ' activeKey={active} onSelect={selected => setActiveKeys(selected)}>
        {data.map((item, i) => (
          <Accordion.Item eventKey={i + 1} key={i} className='FAQ__item'>
            <Accordion.Header>
              <strong>#{i + 1} - {item.title}</strong>
            </Accordion.Header>
            <Accordion.Body>{item.description}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    )
  }

  useEffect(() => {
    setActiveKeys(activeKeys)
    setClient(true)
  }, [activeKeys, setActiveKeys])

  return isClient ? render(activeKeys) : render([])
}

Faq.defaultProps = {
  data: [],
}

Faq.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Faq
