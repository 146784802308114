import React from "react"
import {Container} from "react-bootstrap";
import Layout from "../components/Layout";
import PageLabel from "../components/PageLabel";
import Gopher from "../components/Gopher";
import feedbackGopher from '../images/gophers/feedback gopher.svg'
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/flippers/flipInY.css'

const FeedbackPage = () => {
  return (
    <Layout
      title='Feedback'
      description='Steve Hook Academy Feedback'
      thumbnail='feedback.png'
      pageURL='/feedback'
    >
      <PageLabel
        title="Feedback"
        description="Here's what people think about Steve Hook Academy"
      />
      <Container className='d-flex justify-content-center'>
        <Gopher className='animated flipInY' src={feedbackGopher} alt='Feedback Gopher | Steve Hook Academy Feedback' />
      </Container>

      <Container className='d-flex justify-content-center'>
        <div style={{height: '150px'}}>No results yet</div>
      </Container>
    </Layout>
  )
}

export default FeedbackPage
