import React from 'react'
import {InlineWidget} from "react-calendly";
import 'animate.css/source/zooming_entrances/zoomInDown.css'
import './Meet.scss'

class Meet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  styles = () => {
    let widgetStyles = {
      height: '700px',
    }

    if (window.innerWidth <= 1200) {
      widgetStyles.height = '620px'
    }

    if (window.innerWidth <= 767) {
      widgetStyles.height = '400px'
    }

    return widgetStyles
  }

  settings = {
    hideGdprBanner: true,
  }

  render() {
    const scheduledClassName = this.props.isScheduled ? '' : ' Calendly--not-scheduled'

    return (
      <div className={`Calendly animated zoomInDown` + scheduledClassName}>
        <InlineWidget url={this.props.url} styles={this.styles()} pageSettings={this.settings}/>
      </div>
    )
  }

  handleCalendly = (e) => {
    if (e.data.event && e.data.event.indexOf('calendly') === 0) {
      switch (e.data.event) {
        case 'calendly.event_scheduled':
          this.props.onAPICall(e.data.payload.invitee.uri)
            .then(response => {
              response.json().then(value => {
                this.props.onScheduled(e.data.payload.event.uri, {
                  email: value.resource.email,
                  name: value.resource.name,
                  reschedule_url: value.resource.reschedule_url,
                  cancel_url: value.resource.cancel_url,
                })
              })
            })
            .catch(err => {
              console.error(err);
            });
          break
        default:
          return
      }
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleCalendly);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleCalendly)
  }
}

export default Meet
