import React from 'react'
import './Gopher.scss'

const Gopher = ({src, alt, className}) => {
  return (
    <img className={className ? className + ' Gopher' : 'Gopher'} src={src} alt={alt}/>
  )
}

export default Gopher
