import React from "react";
import {Link, navigate} from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {Col, Container, Row, Stack} from "react-bootstrap";
import logo from '../images/icon200.png'
import './Footer.scss'

const Footer = () => {
  const year = new Date().getFullYear()
  const links = {
    meet: {
      email: 'steevehook@gmail.com'
    },
    follow: {
      facebook: 'https://www.facebook.com/steevehookmd',
      instagram: 'https://instagram.com/steevehook/',
      twitter: 'https://twitter.com/@steevehook',
      reddit: 'https://repl.it/@steevehook',
      youtube: 'https://youtube.com/c/SteveHook',
      github: 'https://github.com/steevehook',
      medium: 'https://steevehook.medium.com/',
      replit: 'https://repl.it/@steevehook',
      discord: 'https://discord.gg/tprewQu',
      tiktok: 'https://www.tiktok.com/@steevehook',
    },
  }

  return (
    <footer className='Footer'>
      <Container>
        <Row>
          <Col className='Footer__logo' xs={12} xl={3}>
            <Stack className='d-flex justify-content-center align-items-center'>
              <Link to='/'>
                <img src={logo} alt="Steve Hook Academy"/>
              </Link>
              <span className='icon-steevehook-academy Footer__logo__label'/>
            </Stack>
          </Col>

          <Col xs={12} xl={2} className='Footer__section'>
            <div className="Footer__label">Let's Meet</div>
            <Stack>
              <Link to='/meet' className="Footer__item">
                <span className='Footer__item__label'>Video Call</span>
                <span className='icon-camera'/>
              </Link>
              <a href={`mailto:${links.meet.email}`} className="Footer__item">
                <span className='Footer__item__label'>Send E-mail</span>
                <span className='icon-send'/>
              </a>
            </Stack>
          </Col>

          <Col xs={12} xl={2} className='Footer__section'>
            <div className="Footer__label">Pricing</div>
            <Stack>
              <AnchorLink to='/pricing#patreon' title='Patreon Payment' className="Footer__item" onAnchorLinkClick={() => navigate('/pricing#patreon')}>
                <span className='Footer__item__label'>Patreon</span>
                <span className='icon-patreon'/>
              </AnchorLink>
              <AnchorLink to='/pricing#paypal' title='PayPal Payment' className="Footer__item" onAnchorLinkClick={() => navigate('/pricing#paypal')}>
                <span className='Footer__item__label'>PayPal</span>
                <span className='icon-paypal'/>
              </AnchorLink>
              <AnchorLink to='/pricing#crypto' title='Crypto Payment' className="Footer__item" onAnchorLinkClick={() => navigate('/pricing#crypto')}>
                <span className='Footer__item__label'>Crypto</span>
                <span className='icon-binance'/>
              </AnchorLink>
              <Link to='/pay' title='Quick Pay' className="Footer__item">
                <span className='Footer__item__label'>Pay</span>
                <span className='icon-wallet'/>
              </Link>
            </Stack>
          </Col>

          <Col xs={12} xl={3} className='Footer__section'>
            <div className="Footer__label">Follow Me</div>
            <Stack direction='horizontal' className='Footer__list'>
              <a target='_blank' rel="noreferrer" href={links.follow.facebook} className="Footer__list__item">
                <span className='icon-facebook'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.instagram} className="Footer__list__item">
                <span className='icon-instagram'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.twitter} className="Footer__list__item">
                <span className='icon-twitter'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.reddit} className="Footer__list__item">
                <span className='icon-reddit'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.youtube} className="Footer__list__item">
                <span className='icon-youtube'/>
              </a>
            </Stack>

            <Stack direction='horizontal' className='Footer__list'>
              <a target='_blank' rel="noreferrer" href={links.follow.github} className="Footer__list__item">
                <span className='icon-github'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.medium} className="Footer__list__item">
                <span className='icon-medium'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.replit} className="Footer__list__item">
                <span className='icon-repl'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.discord} className="Footer__list__item">
                <span className='icon-discord'/>
              </a>
              <a target='_blank' rel="noreferrer" href={links.follow.tiktok} className="Footer__list__item">
                <span className='icon-tiktok'/>
              </a>
            </Stack>
          </Col>

          <Col xs={12} xl={2} className='Footer__section'>
            <div className="Footer__label">Support Me</div>
            <Stack direction='horizontal' className='Footer__list'>
              <AnchorLink target='_blank' rel="noreferrer" to='/support#patreon' className="Footer__list__item" onAnchorLinkClick={() => navigate('/support#patreon')}>
                <span className='icon-patreon'/>
              </AnchorLink>
              <AnchorLink target='_blank' rel="noreferrer" to='/support#paypal' className="Footer__list__item" onAnchorLinkClick={() => navigate('/support#paypal')}>
                <span className='icon-paypal'/>
              </AnchorLink>
              <AnchorLink target='_blank' rel="noreferrer" to='/support#crypto' className="Footer__list__item" onAnchorLinkClick={() => navigate('/support#crypto')}>
                <span className='icon-binance'/>
              </AnchorLink>
              <AnchorLink target='_blank' rel="noreferrer" to='/support#coffee' className="Footer__list__item" onAnchorLinkClick={() => navigate('/support#coffee')}>
                <span className='icon-coffee'/>
              </AnchorLink>
            </Stack>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='Footer__copyright'>
              <span className='icon-academy Footer__copyright__icon'/>
              <span className='Footer__copyright__label'>© 2019-{year}, Steve Hook. All rights reserved</span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
