import React from "react"
import {Container} from "react-bootstrap";
import PageLabel from "../components/PageLabel";
import Layout from "../components/Layout";
import PaymentAgreement from "../components/PaymentAgreement";
import PayPalPayment from "../components/PayPalPayment";
import PatreonPayment from "../components/PatreonPayment";
import CryptoPayment from "../components/CryptoPayment";
import CompanyPayment from "../components/CompanyPayment";
import Gopher from "../components/Gopher";
import businessGopher from "../images/gophers/business gopher.svg";
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/bouncing_entrances/bounceInUp.css'

const PricingPage = () => {
  return (
    <Layout
      title='Pricing'
      description='Steve Hook Academy pricing options and packages. Go (Golang) 1:1 Mentorship with Steve Hook pricing packages: Patreon | PayPal | Crypto'
      thumbnail='pricing.png'
      pageURL='/pricing'
    >
      <PageLabel
        title="Pricing"
        description={<>
          Here's a detailed list of all supported payment plans
          and packages that Steve Hook Academy accepts
        </>}
      />
      <div>
        <PaymentAgreement/>

        <Container className='d-flex justify-content-center'>
          <Gopher
            className='animated bounceInUp'
            src={businessGopher}
            alt="Excited Gopher | Support Steve Hook"
          />
        </Container>

        <PatreonPayment/>
        <PayPalPayment/>
        <CryptoPayment/>
        <CompanyPayment/>
      </div>
    </Layout>
  )
}

export default PricingPage
