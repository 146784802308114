import React from 'react'
import {Link} from "gatsby";
import Carousel from "react-bootstrap/Carousel";
import {Col, Container, Row, Stack} from "react-bootstrap";
import gopherStudies from "../images/gophers/gopher-studies.svg";
import profile from "../images/profile.png";
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/flippers/flip.css'
import './Home.scss'

const Home = () => {
  return (
    <div className='Home'>
      <Container className='Home__intro'>
        <span className='icon-academy Home__intro__academy-icon'/>
        <span className='icon-steevehook-academy Home__intro__academy-text'/>
        <h1 className='Home__intro__title'>
          Become a professional Software Engineer
          using the <strong>Go</strong> programming language today.
        </h1>
        <img
          className='animated flip'
          src={gopherStudies}
          alt='Gopher studies Go'
        />
      </Container>

      <div className='Home__section Home__section--1'>
        <Container className='Home__section__container'>
          <h2 className='Home__section__title'>
            WHAT <span className='icon-checkmark Home__section__icon'/>
          </h2>
          <p className='Home__section__paragraph'>
            The Steve Hook Academy is a 1:1 <strong>mentorship program</strong> aimed for people who
            want to become <strong>Go professionals</strong> and master the <strong>Go programming language</strong>.
          </p>
          <p className='Home__section__paragraph'>
            Whether you're just <strong>tinkering</strong> with the language, planning to use Go in your
            <strong>personal projects</strong> or even planning on making a <strong>career switch</strong>, you'll hit
            quite a few <strong>roadblocks</strong>, that may disappoint you in trying to do so .
          </p>
          <p className='Home__section__paragraph'>
            To help you become a wanted high paying <strong>Go professional</strong>, I'll be
            your <strong>personal mentor</strong> and help
            you <strong>master</strong> the <strong>Go</strong> programming language.
            At all times <strong>you</strong>'ll be the <strong>boss</strong> and I'll be your mentor. I will
            only <strong>guide</strong> you
            along the way, and you will put in the actual <strong>work</strong>. Then <strong>progress</strong> will
            follow.
          </p>

          <h2 className='Home__section__title'>
            !WHAT <span className='icon-not Home__section__icon'/>
          </h2>
          <p className='Home__section__paragraph'>
            The Steve Hook Academy mentorship program is definitely <strong>NOT</strong> a place
            for <strong>lazy people</strong> who expect to be fed with the teaspoon. This is the place
            where you <strong>work hard</strong> and <strong>boss up</strong>. You <strong>write</strong> every single
            LoC, <strong>complete</strong> every
            single task and do things the <strong>Steve Hook way</strong>, without any shortcuts.
          </p>

          <h2 className='Home__section__title'>
            WHO <span className='icon-developer Home__section__icon'/>
          </h2>

          <Stack className='Home__who'>
            <div className='Home__who__item'>
              You're someone who has a big interest in <strong>Go</strong>
            </div>
            <div className='Home__who__item'>
              You come from any <strong>programming background</strong>
            </div>
            <div className='Home__who__item'>
              You have at least gone through <strong>
              <a target='_blank' rel='noreferrer' href="https://go.dev/tour/welcome/1">A tour of Go</a>
            </strong>
            </div>
            <div className='Home__who__item'>
              You like <strong>learning</strong> and <strong>working hard</strong> on yourself
            </div>
            <div className='Home__who__item'>
              Ready to become a high paying <strong>Go professional</strong>
            </div>
            <div className='Home__who__item'>
              You're ready to build an Iron Man suit. Kidding 😋
            </div>
          </Stack>
        </Container>
      </div>

      <div className='Home__section Home__section--2'>
        <Container className='Home__section__container'>
          <h2 className='Home__section__title'>
            FLOW <span className='icon-flow Home__section__icon'/>
          </h2>
          <p className='Home__section__paragraph'>
            The Steve Hook Academy mentorship program will help you take your
            career from someone who has some degree of interest & experience
            in Go, to someone who uses it professionally.
          </p>
        </Container>

        <Carousel interval={10000} controls={false} wrap={true} className='Carousel'>
          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-conference'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">Schedule a Call</h2>
              <h5 className="Carousel__description">
                Go ahead and <Link to='/meet'><strong>schedule</strong></Link> your first 1:1 <strong>video
                call</strong> with me,
                where we'll talk about a custom <strong>mentorship program</strong> for you that matches you
                pace, needs and goals.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-invoice'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">Mentorship Plan</h2>
              <h5 className="Carousel__description">
                Once we agree on a mentorship program, I'll send you an <strong>invoice</strong> that
                you'll pay for. The invoice only represents my <strong>proof of work</strong> & <strong>reward</strong>.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-credit-card'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">Pay Time</h2>
              <h5 className="Carousel__description">
                As soon as you receive an invoice from me, you can go ahead and <strong>pay</strong> for it
                using any of the <Link to='/pricing'>payment</Link> options / packages that work best for you.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-p2p'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">P2P Sessions</h2>
              <h5 className="Carousel__description">
                Once I receive a payment from you, we can proceed with our <strong>1:1 calls</strong>.
                At all times, you own/create <strong>the learning format</strong> that works best for you.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-goal'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">Go Professional</h2>
              <h5 className="Carousel__description">
                In the end you will become a real <strong>problem solver</strong> and a successful
                high paying <strong>Go Professional</strong> that gets to secure the next dream job.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <h2 className='Home__section__title'>
          P2P SESSION <span className='icon-p2p Home__section__icon'/>
        </h2>
        <p className='Home__section__paragraph'>
          Create your custom peer to peer session format that accommodates
          the your rhythm, needs and goals.
        </p>

        <div className='Home__p2p'>
          <h4 className='Home__p2p__item'>
            <span className='Home__p2p__item__bullet'/>
            <span className='Home__p2p__item__description'>
              At all times you <strong>choose</strong> your own <strong>format</strong> & <strong>pace</strong>
            </span>
          </h4>

          <h4 className='Home__p2p__item'>
            <span className='Home__p2p__item__bullet'/>
            <span className='Home__p2p__item__description'>
              <strong>I guide</strong> & <strong>advice</strong> <strong>you</strong>, you put in the <strong>work</strong>
            </span>
          </h4>

          <h4 className='Home__p2p__item'>
            <span className='Home__p2p__item__bullet'/>
            <span className='Home__p2p__item__description'>
              <strong>No shortcuts</strong> / magic. We <strong>implement</strong> everything
            </span>
          </h4>

          <h4 className='Home__p2p__item'>
            <span className='Home__p2p__item__bullet'/>
            <span className='Home__p2p__item__description'>
              We focus more on <strong>concepts</strong>, less on frameworks
            </span>
          </h4>

          <h4 className='Home__p2p__item'>
            <span className='Home__p2p__item__bullet'/>
            <span className='Home__p2p__item__description'>
              I teach you how to think like a <strong>problem solver</strong>
            </span>
          </h4>

          <h4 className='Home__p2p__item'>
            <span className='Home__p2p__item__bullet'/>
            <span className='Home__p2p__item__description'>
              You <strong>work before</strong>, <strong>after</strong> and <strong>during</strong> the sessions
            </span>
          </h4>

          <h4 className='Home__p2p__item'>
            <span className='Home__p2p__item__bullet'/>
            <span className='Home__p2p__item__description'>
              We'll do <strong>P2P coding</strong> / <strong>tips & tricks</strong> / <strong>anything</strong> else
            </span>
          </h4>
        </div>

        <h2 className='Home__section__title Home__section__title--payment'>
          PAYMENT <span className='icon-credit-card Home__section__icon'/>
        </h2>
        <p className='Home__section__paragraph'>
          Feel free to pay as you go or opt for a monthly subscription package
          using any of the payment methods below that works best for you.
        </p>

        <Row className='Home__payment'>
          <Col xs={12} lg={6}>
            <Link to='/pricing#patreon'>
              <div className='Home__payment__item'>
                Patreon <span className='icon-patreon Home__payment__item__icon'/>
              </div>
            </Link>
          </Col>

          <Col xs={12} lg={6}>
            <Link to='/pricing#paypal'>
              <div className='Home__payment__item'>
                PayPal <span className='icon-paypal Home__payment__item__icon'/>
              </div>
            </Link>
          </Col>
        </Row>

        <Row className='Home__payment'>
          <Col xs={12} lg={6}>
            <Link to='/pricing#paypal'>
              <div className='Home__payment__item'>
                Credit Card <span className='icon-credit-card Home__payment__item__icon'/>
              </div>
            </Link>
          </Col>

          <Col xs={12} lg={6}>
            <Link to='/pricing#crypto'>
              <div className='Home__payment__item'>
                Crypto <span className='icon-crypto Home__payment__item__icon'/>
              </div>
            </Link>
          </Col>
        </Row>

        <div className='Home__schedule-call'>
          <span className='icon-camera Home__schedule-call__icon'/>

          <h4 className='Home__schedule-call__title'>
            So, what are you waiting for?
            <br/>
            Let's talk some real business.
          </h4>

          <h5 className='Home__schedule-call__title'>
            Go schedule your first <Link to='/meet'><strong>call</strong></Link> with me.
          </h5>
        </div>
      </div>

      <div className='Home__section Home__section--3'>
        <Container className='Home__section__container'>
          <h2 className='Home__section__title'>
            YOU GET <span className='icon-badge Home__section__icon'/>
          </h2>

          <div className='Home__you-get'>
            <div className='Home__you-get__item'>
              <span className='icon-community Home__you-get__item__icon'/>
              <h2 className='Home__you-get__item__title'>
                Vibrant Community
              </h2>
              <p className='Home__you-get__item__description'>
                Once you become a <strong>Steve Hook Academy student</strong>, this automatically
                makes you part of a private <strong>Discord channel</strong> where you get to interact with other
                fellow <strong>students like you</strong> where you also get to influence how I do things around.
              </p>
            </div>

            <div className='Home__you-get__item'>
              <span className='icon-group Home__you-get__item__icon'/>
              <h2 className='Home__you-get__item__title'>
                Group Sessions
              </h2>
              <p className='Home__you-get__item__description'>
                Besides the one to one video calls I have with individual students,
                the Steve Hook Academy students can also decide on a special topic
                that I can cover free of charge every month for groups of students.
              </p>
            </div>

            <div className='Home__you-get__item'>
              <span className='icon-development Home__you-get__item__icon'/>
              <h2 className='Home__you-get__item__title'>
                Problem Solving
              </h2>
              <p className='Home__you-get__item__description'>
                Unlike many mentorship programs, here we don't do things for the checkmark.
                We avoid <strong>shortcuts, frameworks, magic and copy & pasting</strong>. Instead, I'll help you
                not only become a <strong>Go professional</strong>, but also a real <strong>problem
                solver</strong> using Go.
              </p>
            </div>

            <div className='Home__you-get__item'>
              <span className='icon-briefcase Home__you-get__item__icon'/>
              <h2 className='Home__you-get__item__title'>
                Career Switch
              </h2>
              <p className='Home__you-get__item__description'>
                While Go is a simple language, it can be quite <strong>challenging</strong> for people who want to
                make a <strong>career switch</strong>, requiring one to have a few years of <strong>production
                experience</strong>.
                With this kind of mentorship you'll easily become a <strong>Go professional</strong> and secure any job.
              </p>
            </div>
          </div>

          <h2 className='Home__section__title Home__section__title--dont'>
            I DON'T <span className='icon-wondering Home__section__icon'/>
          </h2>

          <div className='Home__dont'>
            <h5 className='Home__dont__item'>
              <strong>Baby sit</strong> you in the 1:1 sessions
            </h5>
            <h5 className='Home__dont__item'>
              Cover the <strong>Go</strong> programming <strong>basics</strong>
            </h5>
            <h5 className='Home__dont__item'>
              <strong>Pass</strong> the job interviews <strong>for you</strong>
            </h5>
            <h5 className='Home__dont__item'>
              <strong>Know everything</strong> about everything
            </h5>
            <h5 className='Home__dont__item'>
              Like <strong>wasting</strong> any of your / my <strong>time</strong>
            </h5>
          </div>
        </Container>
      </div>

      <div className='Home__section Home__section--4'>
        <Container className='Home__section__container'>
          <h2 className='Home__section__title'>
            ABOUT ME
          </h2>

          <div className='Home__about'>
            <img src={profile} alt="About Steve Hook"/>
          </div>
        </Container>

        <Carousel interval={10000} controls={false} wrap={true} className='Carousel'>
          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-work'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">Experience</h2>
              <h5 className="Carousel__description">
                I have <strong>4+ years</strong> of <strong>production experience</strong> using
                the <strong>Go</strong> programming language.
                In this mentorship program I will share all the knowledge I've gained so far.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-youtube'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">Teaching</h2>
              <h5 className="Carousel__description">
                I have over <strong>3 years</strong> of experience doing interactive <strong>YouTube
                videos</strong> using a
                <strong>pragmatic</strong> and <strong>practical</strong> approach while sharing my engineering
                experience.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="Carousel__item">
            <div className="Carousel__icon">
              <span className='icon-learn'/>
            </div>

            <Carousel.Caption>
              <h2 className="Carousel__title">Learning</h2>
              <h5 className="Carousel__description">
                I obviously don't know it all, I'm a student of the game, just like you,
                but when it comes to <strong>Go</strong>, I can guarantee & promise you're in <strong>good
                hands</strong>.
              </h5>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <h5 className='Home__about__end'>
          Before you take my word for anything, go ahead and check me out
          on my <a href='https://youtube.com/c/SteveHook' rel='noreferrer'
                   target='_blank'><strong>YouTube</strong></a> channel.
          You can see for yourself what's the real deal with
          the quality of my teaching, then don't forget to <strong>come back</strong> and schedule
          a <Link to='/meet'><strong>call</strong></Link> with me, so we can talk some real business as well.
        </h5>
      </div>
    </div>
  )
}

export default Home
