import React from "react"
import {Link} from "gatsby";
import {Container} from "react-bootstrap";
import Layout from "../components/Layout";
import PageLabel from "../components/PageLabel";
import Gopher from "../components/Gopher";
import Faq from "../components/Faq";
import hypnotizedGopher from '../images/gophers/hypnotized gopher.svg'
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/attention_seekers/swing.css'

const FaqPage = () => {
  const faqData = [
    {
      title: <>Do I have to pay for the 1st Meeting Call</>,
      description: <>Absolutely <strong>NOT</strong>. This is how you and I <strong>MEET</strong>, and you get to tell
        me your needs and whether or not
        you are interested in this kind of Mentorship. So, if you haven't already, go ahead and <Link
          to='/meet'>book</Link> your spot right now.</>
    },
    {
      title: <>Do I need to have any experience with Go</>,
      description: <>Generally speaking: No, you <strong>DON'T</strong>, but since this is a <i>Go 1:1 Mentorship
        program</i> it would be nice if you did have a little bit of experience in Go. The kind of mentoring I do
        involves you working. Without minimum <i>Go Basics</i> experience, the mentoring sessions may seem tougher.
        Nevertheless, this should not discourage anyone from applying. If you're really a beginner, make sure to at
        least
        give <a target='_blank' rel='noreferrer' href="https://go.dev/tour/welcome/1">A tour of Go</a> a try.</>
    },
    {
      title: <>What is the format of a 1:1 Mentoring Session</>,
      description: <>There is <strong>no clear format</strong> of the mentoring session. It is all up to the candidate
        to decide and negotiate. It all depends on the <strong>candidate's rhythm</strong>, <strong>preferred
          topics </strong>
        and needs, and really anything that works best for and in the interest of the candidate. <strong>You're in
          complete charge </strong>
        of what we are doing, how fast we are doing it, <strong>you're</strong> basically <strong>the boss</strong> and
        you tell me what you need
        from me and I provide. If you haven't already, make sure to <Link to='/meet'>Schedule</Link> your first meeting
        call with me and let's talk about
        what you need and what works the best for you.</>
    },
    {
      title: <>Are the 1:1 Mentoring Sessions only about Go</>,
      description: <>The main purpose of these Mentoring Sessions is of course to teach you and help you <strong>become
        a Go professional</strong>.
        So, normally everything should be around <strong>Go topics</strong> and everything that's Go related. However,
        mentoring
        is much more than just a specific language, this is why <strong>feel free</strong> to drop anything in the
        conversation, anything
        you think may make you a <i>better Software Engineer</i>. Remember <strong>you're </strong>
        still <strong>the one who's in charge</strong>, you're still the <strong>boss</strong> around here. I'm only
        here to help you, and in the process you help me as well.</>
    },
    {
      title: <>How much time will it take me to become a Go professional</>,
      description: <>Time is <strong>relative</strong> to the amount of <strong>hard
        work</strong> and <strong>perseverance</strong> you're ready to put in.
        It's <strong>all up to you</strong> and how <strong>hard</strong> you'll <strong>work</strong>. I am here to be
        your mentor, not your baby sitter.
        I will <strong>guide you</strong> and <strong>help you</strong>, but I will not do the work for you. So, you can
        nail the dream gopher job in no time,
        or you can constantly struggle with the basics. The <strong>goal</strong> of this Mentorship
        is <strong>NOT</strong> to <strong>GIVE</strong> you or
        stuff information in your head, it's to <strong>TEACH</strong> you how to <strong>FEED
          YOURSELF</strong> instead, how to <strong>find solutions</strong>,
        how to <strong>think</strong> in a critical way and to ultimately become an <strong>Engineer</strong>,
        a <strong>problem solver</strong> using Go.</>
    },
    {
      title: <>Are you going to help me get a job</>,
      description: <>I <strong>don't promise</strong> you jobs,
        because <strong>passing</strong> an <strong>interview</strong> is <strong>subjective</strong> and depends
        on too many factors that are not even up to you. What <strong>I can promise</strong> though, is to <strong>help
          you</strong> become a <strong>Go professional </strong>
        and <strong>think</strong> like a real <strong>Software Engineer</strong>, like a real <strong>problem
          solver</strong>. That being said, I think securing a job will become
        secondary and will eventually be a trivial task, rather than the main goal of this Mentoring.</>
    },
    {
      title: <>How long is a 1:1 Mentoring Session</>,
      description: <>A mentoring session should rarely take more than <strong>1 hour</strong>. Anything that goes the
        extra time can be
        taken <strong>offline</strong> or in the <strong>next session</strong>. If making the best use of 1 mentoring
        session, 1 hour is more than enough to
        digest a lot of information that is useful and valuable enough to be retained.</>
    },
    {
      title: <>How do I make the best out of a 1:1 Mentoring Session</>,
      description: <>As stated before, <strong>you</strong>'re always the <strong>boss</strong>, you're always the
        person in charge.
        Steve Hook is just your mentor. I'm here to <strong>guide</strong> and <strong>help</strong> you achieve your
        goals.
        To make the best out of 1 hour (session), I recommend to <strong>get ready</strong> before each session. That
        means,
        preparing <strong>working materials</strong>, preparing your <strong>homework</strong>, getting myself ready by
        sending me
        the <strong>topics</strong>, <strong>links</strong>, <strong>code</strong> you want to dive into before the
        actual session.
        That alone can <strong>save</strong> both of us a lot of <strong>time</strong> and make our sessions much
        more <strong>efficient</strong> and <strong>productive</strong>.
        I don't know it all, so giving me the time and resources to <strong>prepare</strong> by sending me materials
        prior to our session is a huge improvement
        which will definitely make the best out of our sessions. Also providing me with <strong>feedback</strong> always
        helps. So make sure to constantly feed me
        with feedback, it helps myself and yourself spend the time in the best way possible.</>
    },
    {
      title: <>Do you also consider mentoring groups of people</>,
      description: <>If there's a <strong>high enough demand</strong>, mentoring groups for a lower price is an option.
        For that to
        happen I need groups of people with relatively the <strong>same interest</strong> and more or less the <strong>same
          experience</strong> and
        exposure to Go. Will keep you up to date if such an option becomes available.</>
    },
    {
      title: <>Does the Mentoring Session extend offline as well</>,
      description: <>Our mentoring sessions will <i>mostly</i> involve <strong>online work</strong>, but you definitely
        will have extra work
        and <strong>homework</strong> that you'll have to do prior to our next session, that of course does not mean
        I'll be involved as much after
        the session (offline). Also, besides our online sessions, you will have access to the Discord Community where
        you can <strong>interact</strong>
        with others like you or <strong>DM me</strong> directly.</>
    },
    {
      title: <>Can I DM you outside of our Mentoring Sessions</>,
      description: <>DM-ing me is <strong>always welcome</strong> and a good thing to do. I promise to respond in
        a <strong>timely manner</strong> and
        make sure I provide you with the <strong>necessary help</strong>. However, be <strong>mindful</strong> of
        my <strong>personal time</strong> as well.
        <strong> Avoid spamming</strong> me or overloading me with <strong>too many messages</strong>, chances are I
        will delay my response in such a case.
        Once more, be mindful of my time and keep things <strong>moderate</strong> when it comes to DM-ing.</>
    },
    {
      title: <>Is there a dedicated Community for all the Steve Hook Academy students</>,
      description: <>Like I said before, you will have access to a <strong>dedicated room</strong> on our <strong>Discord
        Community</strong>,
        where you will get to <strong>interact</strong> with others like you. So I got you covered on the community side
        of things. Also,
        at any point in time, you are always welcome to <strong>DM me</strong> and talk about stuff.</>
    },
    {
      title: <>Do mentoring sessions in the PatreonPayment package transfer to the next week</>,
      description: <>The PatreonPayment package includes <strong>2 hours (sessions) / week</strong>. If those 2 hours
        have not
        been used,
        you <strong>CAN'T</strong> transfer them or reschedule them for the next week. Be <strong>mindful</strong> of
        your time and my time
        and schedule things in a timely manner.</>
    },
    {
      title: <>What's the price for extra hours as a Patron / PayPal Subscription</>,
      description: <>If the PatreonPayment package which includes 2 hours / week is not enough, you can always opt for
        extra
        hours.
        For more information check out the <Link to='/pay'>Extra Pay</Link> section</>
    },
    {
      title: <>Can I get a refund and how does that work</>,
      description: <>At any point in time if you're not satisfied with how I do things and want to interrupt our
        collaboration,
        you can of course request a refund if you're in
        the <strong>PatreonPayment</strong> or <strong>PayPal</strong> package.
        For more information on how refunds work on PatreonPayment, please refer
        to <a target='_blank' rel='noreferrer'
              href='https://support.patreon.com/hc/en-us/articles/205032045-Patreon-s-refund-policy'> PatreonPayment's
          Refund
          Policy</a>.
        For PayPal refunds refer to <a target='_blank' rel='noreferrer'
                                       href='https://www.paypal.com/gf/smarthelp/article/i-want-a-refund.-how-do-refunds-work-faq3248'> How
          do refunds work on PayPal</a>.</>
    },
    {
      title: <>Can I pay you in advance</>,
      description: <>I prefer <strong>monthly payments</strong> instead, which at the moment is accepted only via
        Patreon choosing the <strong>Steve Hook Academy</strong> tier
        or <strong>PayPal</strong> monthly <strong>subscription</strong> package. Anything else is to be considered
        an <strong>hourly</strong> based <strong>payment</strong>. It's in both of our interest and
        also is the safest route and guarantee for me as a Creator. Like I said before, there's place
        for <strong>exceptions</strong>, as long as the <strong>services</strong> provided are <strong>high
          quality</strong> and
        the <strong>trust</strong> between you and I is not violated.</>
    },
  ]

  return (
    <Layout
      title='FAQ'
      description='Frequently asked questions and answers about Steve Hook Academy and Go (Golang) 1:1 Mentorship with Steve Hook'
      thumbnailURL='faq.png'
      pageURL='/faq'
    >
      <PageLabel
        title="FAQ"
        description="Here are some frequently asked questions you might want to check first"
      />

      <Container className='d-flex justify-content-center'>
        <Gopher
          className='animated swing'
          src={hypnotizedGopher}
          alt='Hypnotized Gopher | Steve Hook Academy FAQ'
        />
      </Container>

      <div className='d-flex justify-content-center'>
        <Faq data={faqData}/>
      </div>
    </Layout>
  )
}

export default FaqPage
