import React from "react";
import {Col, Container, ListGroup, Row, Stack} from "react-bootstrap";
import './PatreonPayment.scss'
import icon from '../images/icon200.png'

const PatreonPayment = () => {
  return (
    <div className='PatreonPayment' id='patreon'>
      <Container className='d-flex align-items-center flex-column text-center'>
        <Row>
          <Col xs={12} lg={6} className='PatreonPayment__section'>
            <h2 className='PatreonPayment__title'>
              Patreon
            </h2>
            <h5>
              Monthly subscription that includes: <strong>2 sessions</strong> (hours) / week
            </h5>

            <a href="https://www.patreon.com/join/steevehook/checkout?rid=8399549" target='_blank' rel='noreferrer'>
              <div className='PatreonPayment__tier'>
                <img src={icon} alt="Steve Hook Academy Patreon Tier"/>
              </div>
            </a>

            <div className='PatreonPayment__price'>
              <h5 className='PatreonPayment__price__title'>
                Steve Hook Academy
              </h5>
              <div className='PatreonPayment__price__tag'>
                <span className='icon-price-tag'/> $200 / month
              </div>
            </div>

            <Stack className='PatreonPayment__perks'>
              <div className='PatreonPayment__perk'>
                2 sessions (hours) / week
              </div>
              <div className='PatreonPayment__perk'>
                Includes Premium Hook perks
              </div>
              <div className='PatreonPayment__perk'>
                Merch gift every 3 months
              </div>
              <div className='PatreonPayment__perk'>
                Special Discord role
              </div>
            </Stack>
          </Col>

          <Col xs={12} lg={6} className='PatreonPayment__section'>
            <div className='PatreonPayment__benefits__label'>
              <span className='icon-patreon PatreonPayment__benefits__icon'/>
              <h3 className='PatreonPayment__benefits__title'>Patreon Benefits</h3>
            </div>

            <ListGroup className='PatreonPayment__benefits' variant='flush'>
              <ListGroup.Item className='PatreonPayment__benefit'>
                Free 15 min(s) <strong>Bi-weekly calls</strong>
              </ListGroup.Item>
              <ListGroup.Item className='PatreonPayment__benefit'>
                YouTube video <strong>shout-outs</strong>
              </ListGroup.Item>
              <ListGroup.Item className='PatreonPayment__benefit'>
                <strong>Patron only</strong> posts & messages
              </ListGroup.Item>
              <ListGroup.Item className='PatreonPayment__benefit'>
                <strong>BTS</strong> YouTube contents
              </ListGroup.Item>
              <ListGroup.Item className='PatreonPayment__benefit'>
                Free access to <strong>Premium</strong> resources,
                including current/future courses
              </ListGroup.Item>
              <ListGroup.Item className='PatreonPayment__benefit'>
                Patreon merch <strong>gift</strong> every 3 months
              </ListGroup.Item>
              <ListGroup.Item className='PatreonPayment__benefit'>
                Special <strong>Discord</strong> role & room
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PatreonPayment
