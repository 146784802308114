import React from "react"
import PageLabel from "../components/PageLabel";
import Layout from "../components/Layout";
import Support from "../components/Support";

const SupportPatreonPage = () => {
  return (
    <Layout
      title='Steve Hook Support'
      description='Support Steve Hook using Patreon, PayPal, Crypto or Buy me a Coffee'
      thumbnail='support.png'
      pageURL='/support'
    >
      <PageLabel
        title="Support"
        description="Wanna support me? 🤑"
      />
      <Support/>
    </Layout>
  )
}

export default SupportPatreonPage
