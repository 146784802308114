import React, {useRef, useState} from "react";
import {Link} from "gatsby"
import {Nav, Navbar} from "react-bootstrap";
import {useOnClickOutside} from "../hooks/click-outside";
import './Header.scss'

const Header = () => {
  const [expanded, toggleExpanded] = useState(false)
  const ref = useRef();

  useOnClickOutside(ref, () => toggleExpanded(false));

  return (
    <div className="Header" ref={ref}>
      <Navbar className="Header__nav" expand="lg" expanded={expanded}>
        <Link to='/' className='Header__home'>
          <span className='Header__nav__brand'>Steve Hook Academy</span>
          <span className='icon-academy'/>
        </Link>

        <Navbar.Toggle
          aria-controls="header-navigation-menu"
          className='Header__nav__toggle'
          onClick={() => toggleExpanded(!expanded)}
        >
          <span className={expanded ? 'icon-menu-open' : 'icon-menu'}/>
        </Navbar.Toggle>

        <Navbar.Collapse id="header-navigation-menu" className='Header__nav__menu'>
          <Nav>
            <Link to='/faq' className='Header__nav__item'>
              <span className='Header__nav__label'>FAQ</span>
              <span className='icon-forum'/>
            </Link>

            <Link to='/pricing' className='Header__nav__item'>
              <span className='Header__nav__label'>Pricing</span>
              <span className='icon-currency-dollar'/>
            </Link>

            <Link to='/pay' className='Header__nav__item'>
              <span className='Header__nav__label'>Pay</span>
              <span className='icon-wallet'/>
            </Link>

            <Link to='/support' className='Header__nav__item'>
              <span className='Header__nav__label'>Support</span>
              <span className='icon-support'/>
            </Link>

            <Link to='/feedback' className='Header__nav__item'>
              <span className='Header__nav__label'>Feedback</span>
              <span className='icon-rating'/>
            </Link>

            <Link to='/meet' className="btn btn-outline-primary Header__nav__item">
              <span className='Header__nav__label'>Let's Meet</span>
              <span className='icon-camera'/>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
