import React from 'react'
import Layout from "../components/Layout";
import Home from "../components/Home";

const IndexPage = () => {
  const homeDescription = `Steve Hook Academy. Become a Go (Golang) professional with Steve Hook and apply for 1:1 Go Mentorship program`

  return (
    <Layout title='Home' description={homeDescription}>
      <Home/>
    </Layout>
  )
}

export default IndexPage
