import React from "react"
import {navigate} from "gatsby";
import {Container} from "react-bootstrap";
import Layout from "../components/Layout";
import PageLabel from "../components/PageLabel";
import Gopher from "../components/Gopher";
import ThankYou from "../components/ThankYou";
import lovingGopher from '../images/gophers/loving gopher.svg'
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/attention_seekers/pulse.css'

const ThankYouPage = (props) => {
  let displayThankYou = false
  if (props.location.state && props.location.state.method) {
    const method = props.location.state.method
    if (method === "paypal-one" || method === "paypal-many" || method === "paypal-subscription") {
      displayThankYou = true
    }
  }

  if (typeof window === "undefined") {
    return ""
  }

  if (!displayThankYou) {
    navigate("/")
    return ""
  }

  return (
    <Layout
      title='Thank You'
      description='Payment thank you page'
    >
      <PageLabel
        title="Thank You"
      />

      <Container className='d-flex justify-content-center'>
        <Gopher
          className='animated pulse'
          src={lovingGopher}
          alt='Loving Gopher | Steve Hook Academy Thank You'
        />
      </Container>

      <ThankYou/>
    </Layout>
  )
}

export default ThankYouPage
