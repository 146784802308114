import React from "react";

const PageLabel = (props) => {
  const {title, description, children} = props;
  return (
    <div className="PageLabel">
      <h1 className="PageLabel__title">{title}</h1>
      {description === '' ? '' : <p className="PageLabel__description">{description}</p>}
      {children}
    </div>
  );
};

export default PageLabel;
