import React, {useState} from 'react'
import {Link, navigate} from "gatsby";
import {Col, Container, Row} from "react-bootstrap";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import Loader from "./Loader";
import './PayPalPayment.scss'

const PayPalPayment = () => {
  const [paypalSubscriptionLoaded, setPaypalSubscriptionLoaded] = useState(false)
  const [paypalExtraSessionLoaded, setPaypalExtraSessionLoaded] = useState(false)
  const paypalSubscription = {
    options: {
      "data-sdk-integration-source": "button-factory",
      "data-namespace": "paypal-subscription",
      "client-id": "AbLpoqfCm62ieA0V5DDKZX7yLHoRnk4UkvbjzNFDvtFGfDUNW8DqDqCgRQi2-MzEAfxifRMeeUfjE9sJ",
      "vault": true,
      "intent": "subscription",
      "currency": "USD",
    },
    style: {
      layout: "vertical",
      color: "gold",
      label: "subscribe",
      shape: "rect"
    },
    createSubscription: (data, actions) => {
      return actions.subscription.create({
        plan_id: 'P-9PV94488S2114913YMI4GRTY'
      });
    },
    onInit: () => {
      setPaypalSubscriptionLoaded(true)
    },

    onApprove: async (data, actions) => {
      return actions.order.capture().then(function (orderData) {
        navigate("/thankyou", {
          state: {
            method: "paypal-subscription"
          }
        })
      });
    },
  }
  const paypalExtraSession = {
    options: {
      "data-sdk-integration-source": "button-factory",
      "data-namespace": "paypal-extra-session",
      "client-id": "AbLpoqfCm62ieA0V5DDKZX7yLHoRnk4UkvbjzNFDvtFGfDUNW8DqDqCgRQi2-MzEAfxifRMeeUfjE9sJ",
      "enable-funding": "venmo",
      "currency": "USD",
    },
    style: {
      layout: "vertical",
      color: "black",
      label: "paypal",
      shape: "rect"
    },

    onInit: () => {
      setPaypalExtraSessionLoaded(true)
    },

    createOrder: function (data, actions) {
      return actions.order.create({
        purchase_units: [{
          "description": "Steve Hook Academy Extra Session",
          "amount": {"currency_code": "USD", "value": 25}
        }]
      });
    },

    onApprove: async (data, actions) => {
      return actions.order.capture().then(function (orderData) {
        navigate("/thankyou", {
          state: {
            method: "paypal-one"
          }
        })
      });
    },

    onError: function (err) {
      console.log(err);
    }
  }

  return (
    <div id='paypal' className='PayPalPayment'>
      <Loader loading={!(paypalSubscriptionLoaded && paypalExtraSessionLoaded)}/>
      <Container>
        <Row className='text-center'>
          <Col xs={12}>
            <h2 className='PayPalPayment__title'>PayPal</h2>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={6}>
            <PayPalScriptProvider options={paypalSubscription.options}>
              <div
                style={{display: paypalSubscriptionLoaded && paypalExtraSessionLoaded ? 'block' : 'none'}}
                className='PayPalPayment__container'
              >
                <div className='PayPalPayment__label'>
                  <h3>
                    Subscription
                  </h3>
                  <h3 className='PayPalPayment__price'>
                    <span className='icon-price-tag'/> $200 / month
                  </h3>

                  <h5 className='PayPalPayment__description'>
                    PayPal monthly subscription that includes <strong>2 sessions</strong> (hours) / week.
                    For additional sessions use the extra option.
                  </h5>
                </div>
                <PayPalButtons
                  className='PayPalPayment__buttons'
                  style={paypalSubscription.style}
                  createSubscription={paypalSubscription.createSubscription}
                  onApprove={paypalSubscription.onApprove}
                  onInit={paypalSubscription.onInit}
                />
              </div>
            </PayPalScriptProvider>
          </Col>

          <Col xs={12} lg={6}>
            <PayPalScriptProvider options={paypalExtraSession.options}>
              <div
                style={{display: paypalSubscriptionLoaded && paypalExtraSessionLoaded ? 'block' : 'none'}}
                className='PayPalPayment__container'
              >
                <div className='PayPalPayment__label'>
                  <h3>
                    Extra Session
                  </h3>
                  <h3 className='PayPalPayment__price'>
                    <span className='icon-price-tag'/> $25 / session
                  </h3>

                  <h5 className='PayPalPayment__description'>
                    Use this option if you want to pay as you go or
                    get an extra session. If you need more than 2 sessions
                    per week, you can check out the <Link to='/pay'>Pay</Link> page.
                  </h5>
                </div>
                <PayPalButtons
                  className='PayPalPayment__buttons'
                  style={paypalExtraSession.style}
                  createOrder={paypalExtraSession.createOrder}
                  onApprove={paypalExtraSession.onApprove}
                  onInit={paypalExtraSession.onInit}
                />
              </div>
            </PayPalScriptProvider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PayPalPayment
