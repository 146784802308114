import React, {useState} from "react"
import {Col, Container, Row, Stack} from "react-bootstrap";
import Clipboard from "./Clipboard";
import CryptoQRCodeModal from "./CryptoQRCodeModal";
import Gopher from "./Gopher";
import bmcLogo from '../images/social/bmc.svg'
import crypto3USDT from '../images/crypto/3usdt-small.png'
import crypto10USDT from '../images/crypto/10usdt-big.png'
import crypto25USDT from '../images/crypto/25usdt-premium.png'
import cryptoUSDTDeposit from '../images/crypto/steevehook-usdt-deposit.png'
import cryptoBinanceID from '../images/crypto/steevehook-binance-id.png'
import smallHook from '../images/patreon/small-hook.png'
import bigHook from '../images/patreon/big-hook.png'
import premiumHook from '../images/patreon/premium-hook.png'
import excitedGopher from "../images/gophers/excited gopher.svg";
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/bouncing_entrances/bounceIn.css'
import './Support.scss'

const Support = () => {
  const [showModal, setShowModal] = useState(false);
  const [qrCode, setQRCode] = useState('')
  const [tier, setTier] = useState('')
  const handleCryptoModal = (image, tier) => {
    setQRCode(image)
    setTier(tier)
    setShowModal(true)
  }

  return (
    <Container className='Support__container'>
      <div id="patreon" className='Support__section Support__patreon'>
        <Gopher
          className='animated bounceIn'
          src={excitedGopher}
          alt="Excited Gopher | Support Steve Hook"
        />

        <Row>
          <Col xs={12}>
            <h2 className='Support__title'>Patreon</h2>
            <p className='Support__description'>
              If you appreciate what I do and would like to monthly support me,
              you can choose any of the following Patreon tiers that works best for you.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={4} className='Support__patreon__tier'>
            <a href="https://www.patreon.com/join/steevehook/checkout?rid=4343740" target='_blank' rel='noreferrer'>
              <img src={smallHook} alt="$3 - Small Hook Patreon Tier"/>
            </a>
            <h6 className='Support__patreon__tier__price'>
              SMALL HOOK ($3)
            </h6>
            <Stack className='Support__patreon__tier__benefits'>
              <div className='Support__patreon__perk'>
                Patron-only posts, messages and shout-outs
              </div>
              <div className='Support__patreon__perk'>
                Weekly patron special video
              </div>
              <div className='Support__patreon__perk'>
                Channel early updates
              </div>
              <div className='Support__patreon__perk'>
                Special Discord role
              </div>
            </Stack>
          </Col>

          <Col xs={12} lg={4} className='Support__patreon__tier'>
            <a href="https://www.patreon.com/join/steevehook/checkout?rid=4343741" target='_blank' rel='noreferrer'>
              <img src={bigHook} alt="$10 - Big Hook Patreon Tier"/>
            </a>
            <h6 className='Support__patreon__tier__price'>
              BIG HOOK ($10)
            </h6>
            <Stack className='Support__patreon__tier__benefits'>
              <div className='Support__patreon__perk'>
                <i>Everything from Small Hook</i>
              </div>
              <div className='Support__patreon__perk'>
                Early access to exclusive & behind the scenes content
              </div>
              <div className='Support__patreon__perk'>
                Free / Early Access to Premium resources
              </div>
              <div className='Support__patreon__perk'>
                Special Discord role
              </div>
            </Stack>
          </Col>

          <Col xs={12} lg={4} className='Support__patreon__tier'>
            <a href="https://www.patreon.com/join/steevehook/checkout?rid=7299754" target='_blank' rel='noreferrer'>
              <img src={premiumHook} alt="$25 - Premium Hook Patreon Tier"/>
            </a>
            <h6 className='Support__patreon__tier__price'>
              PREMIUM HOOK ($25)
            </h6>
            <Stack className='Support__patreon__tier__benefits'>
              <div className='Support__patreon__perk'>
                <i>Everything from Big Hook</i>
              </div>
              <div className='Support__patreon__perk'>
                Bi-Weekly 1:1 Call
              </div>
              <div className='Support__patreon__perk'>
                Receive a Patron Merch gift every 3 months
              </div>
              <div className='Support__patreon__perk'>
                Special Discord role
              </div>
            </Stack>
          </Col>
        </Row>
      </div>

      <div id="paypal" className='Support__section Support__paypal'>
        <Row>
          <Col xs={12}>
            <h2 className='Support__title'>PayPal</h2>
            <p className='Support__description'>
              If you're interested just in a 1 time contribution you can do that too.
              Feel free to support me with a 1 time donation on PayPal.
              I appreciate and am thankful for each and every support that I can get.
            </p>
          </Col>

          <Col xs={12}>
            <a href="https://paypal.me/steevehook" rel='noreferrer' target='_blank'>
              <div className='Support__paypal__button'>
                Support <span className='icon-paypal'/>
              </div>
            </a>
          </Col>
        </Row>
      </div>

      <div id="crypto" className='Support__section Support__crypto__container'>
        <Row>
          <Col xs={12}>
            <h2 className='Support__title'>Crypto</h2>
            <p className='Support__description'>
              If you're into crypto, you can install the <a
              href="https://accounts.binance.com/en/register?ref=150189173" rel='noreferrer'
              target='_blank'>Binance</a> app
              and scan one of the predefined QR codes that works best for you.
            </p>
          </Col>
        </Row>

        <CryptoQRCodeModal
          show={showModal}
          onHide={() => setShowModal(false)}
          qrCode={qrCode}
          label={tier}
        />
        <Row>
          <Col
            xs={12}
            lg={4}
            className='Support__crypto'
            onClick={() => handleCryptoModal(crypto3USDT, (
              <div className='Support__crypto__modal'>
                <h3 className='Support__crypto__modal__label'>Small Hook</h3>
                <div className='Support__crypto__modal__price'>
                  3 <span className='icon-usdt'/>
                </div>
              </div>
            ))}
          >
            <img src={crypto3USDT} alt="3 USDT - Small Hook Donation (Crypto)"/>

            <h6 className='Support__crypto__price__tier'>SMALL HOOK</h6>
            <div className='Support__crypto__price__tag'>
              3 <span className='icon-usdt'/>
            </div>
          </Col>

          <Col
            xs={12}
            lg={4}
            className='Support__crypto'
            onClick={() => handleCryptoModal(crypto10USDT, (
              <div className='Support__crypto__modal'>
                <h3 className='Support__crypto__modal__label'>Big Hook</h3>
                <div className='Support__crypto__modal__price'>
                  10 <span className='icon-usdt'/>
                </div>
              </div>
            ))}
          >
            <img src={crypto10USDT} alt="10 USDT - Big Hook Donation (Crypto)"/>

            <h6 className='Support__crypto__price__tier'>BIG HOOK</h6>
            <div className='Support__crypto__price__tag'>
              10 <span className='icon-usdt'/>
            </div>
          </Col>

          <Col
            xs={12}
            lg={4}
            className='Support__crypto'
            onClick={() => handleCryptoModal(crypto25USDT, (
              <div className='Support__crypto__modal'>
                <h3 className='Support__crypto__modal__label'>Premium Hook</h3>
                <div className='Support__crypto__modal__price'>
                  25 <span className='icon-usdt'/>
                </div>
              </div>
            ))}
          >
            <img src={crypto25USDT} alt="25 USDT - Premium Hook Donation (Crypto)"/>

            <h6 className='Support__crypto__price__tier'>PREMIUM HOOK</h6>
            <div className='Support__crypto__price__tag'>
              25 <span className='icon-usdt'/>
            </div>
          </Col>
        </Row>

        <Row className='Support__addresses'>
          <Col xs={12}>
            <p className='Support__description Support__description--addresses'>
              You might as well transfer any custom amount or even
              use the USDT crypto deposit address with the app of your choice
            </p>
          </Col>

          <Col
            lg='auto'
            xs={12}
            className='Support__crypto'
            onClick={() => handleCryptoModal(cryptoBinanceID, (
              <div className='Support__crypto__address'>
                <strong>267685624</strong>
                <Clipboard value='267685624'/>
              </div>
            ))}
          >
            <img src={cryptoBinanceID} alt="Crypto Binance ID to send coins to"/>
            <h6 className='Support__crypto__price__tier'>
              Binance ID
            </h6>
          </Col>

          <Col
            lg='auto'
            xs={12}
            className='Support__crypto'
            onClick={() => handleCryptoModal(cryptoUSDTDeposit, (
              <div className='Support__crypto__address'>
                <span className='Support__crypto__address__value'>
                  <strong>0xb18ecc45ae41d9f106b03615b0a86a9c04287e46</strong>
                </span>
                <Clipboard value='0xb18ecc45ae41d9f106b03615b0a86a9c04287e46'/>
              </div>
            ))}
          >
            <img src={cryptoUSDTDeposit} alt="Crypto USDT deposit address"/>
            <h6 className='Support__crypto__price__tier'>
              USDT Deposit <span className='icon-usdt'/>
            </h6>
          </Col>
        </Row>
      </div>

      <div id='coffee' className='Support__section'>
        <div>
          <h2 className='Support__title'>Buy me a Coffee</h2>
          <p className='Support__description'>Forgot to tell you, I also accept coffee coins</p>
          <a href="https://www.buymeacoffee.com/steevehook" rel='noreferrer' target='_blank' className='Support__bmc'>
            <img alt='BMC' src={bmcLogo}/>
          </a>
        </div>
      </div>

      <div id='thank_you' className='Support__section Support__thank-you__container'>
        <Row>
          <Col xs={12}>
            <h2>Thank You</h2>
            <p className='Support__description'>
              I am grateful and thankful for all of your support. Whether it's
              through your feedback, comments, community involvement or financial
              support, I thank you for absolutely everything. And just so you know,
              you inspire me to continue to do this and you're always the best.
            </p>
          </Col>

          <Col xs={12}>
            <span className='icon-heart Support__thank-you'/>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Support
