exports.shouldUpdateScroll = ({prevRouterProps, routerProps, getSavedScrollPosition}) => {
  // do not scroll back to top if paths are the same and query params are different
  // this is a small hack for the current Gatsby version
  if (prevRouterProps !== undefined) {
    const prevLoc = prevRouterProps.location
    const newLoc = routerProps.location
    if (prevLoc.pathname === newLoc.pathname && prevLoc.search !== newLoc.search) {
      return false
    }

    const [x, y] = getSavedScrollPosition(prevLoc)
    if (x === 0 && y === 0) {
      return false
    }
  }

  return true
}
