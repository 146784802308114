import React from "react"
import {Helmet} from "react-helmet";
import {graphql, useStaticQuery} from "gatsby"
import NotFound from "../components/NotFound";

const NotFoundPage = () => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <main>
      <Helmet
        title='Not Found'
        titleTemplate={`%s | ${site.siteMetadata.title}`}
      />
      <NotFound/>
    </main>
  )
}

export default NotFoundPage
