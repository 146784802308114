import React from 'react'
import {Link} from "gatsby";
import {Container} from "react-bootstrap";
import lostGopher from '../images/gophers/lost-gopher.svg'
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/attention_seekers/jello.css'
import './NotFound.scss'

const NotFound = () => {
  return (
    <Container className='NotFound'>
      <img
        className='animated jello'
        src={lostGopher}
        alt="Lost gopher, page not found"
      />

      <div className='text-center'>
        <h1 className='NotFound__title'>
          Uh oh, lost?
        </h1>

        <div className='NotFound__link'>
          <Link to="/">
            <span className='icon-academy'/>
            <span className='icon-steevehook-academy'/>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default NotFound
