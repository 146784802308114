import React from "react"
import PropTypes from "prop-types";
import {graphql, useStaticQuery} from "gatsby"
import {Helmet} from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../sass/bootstrap.scss';
import './Layout.scss'

const Layout = ({title, description, lang, meta, thumbnail, pageURL, children}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const thumbnailsURL = `https://academy.steevehook.com/thumbnails`
  const baseURL = `https://academy.steevehook.com`

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: `${thumbnailsURL}/${thumbnail}`,
          },
          {
            property: `og:url`,
            content: `${baseURL}${pageURL}`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ].concat(meta)}
      />
      <Header/>
      <main>
        {children}
      </main>
      <Footer/>
    </>
  )
};

Layout.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  pageURL: `/`,
  thumbnail: `default.png`,
}

Layout.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  pageURL: PropTypes.string,
}

export default Layout
