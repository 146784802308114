import React from "react"
import {Container} from "react-bootstrap";
import PageLabel from "../components/PageLabel";
import Layout from "../components/Layout";
import PaymentAgreement from "../components/PaymentAgreement";
import PayPalExtraPayment from "../components/PayPalExtraPayment";
import CryptoPayment from "../components/CryptoPayment";
import CompanyPayment from "../components/CompanyPayment";
import Gopher from "../components/Gopher";
import slickGopher from '../images/gophers/slick gopher.svg'
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css'
import 'animate.css/source/attention_seekers/bounce.css'

const QuickPayPage = () => {
  return (
    <Layout
      title='Quick Pay'
      description='Steve Hook Academy Quick Pay using PayPal or Crypto options'
      thumbnail='pay.png'
      pageURL='/pay'
    >
      <PageLabel
        title="Quick Pay"
        description="Looking for extra sessions or 1 time sessions?"
      />
      <div>
        <PaymentAgreement/>

        <Container className='d-flex justify-content-center'>
          <Gopher
            className='animated bounce'
            src={slickGopher}
            alt='Slick Gopher | Steve Hook Academy Quick Pay'
          />
        </Container>

        <PayPalExtraPayment/>
        <CryptoPayment/>
        <CompanyPayment/>
      </div>
    </Layout>
  )
}

export default QuickPayPage
