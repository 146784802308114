import React, {useEffect, useState} from "react"
import {Button, Container} from "react-bootstrap";
import PageLabel from "../components/PageLabel";
import Layout from "../components/Layout";
import Meet from "../components/Meet";
import Loader from "../components/Loader";
import Gopher from "../components/Gopher";
import meetingGopher from "../images/gophers/meeting gopher.svg";
import lovingGopher from "../images/gophers/loving gopher.svg";
import wonderingGopher from "../images/gophers/wondering gopher.svg";
import 'animate.css/source/_vars.css'
import 'animate.css/source/_base.css';
import 'animate.css/source/attention_seekers/pulse.css';
import 'animate.css/source/fading_entrances/fadeIn.css';

const MeetPage = () => {
  const eventKey = 'event'
  const initialMeetURL = 'https://calendly.com/steevehook'
  const [meetURL, setMeetURL] = useState(initialMeetURL);
  const [scheduled, setScheduled] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invitee, setInvitee] = useState(null);

  const handleCalendlyAPI = (uri) => {
    return fetch(uri, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ3OTYxMzA0LCJqdGkiOiJkMTVmOWE2My1jZGM4LTQ0NzAtYmY4OC1iMzA3NjRiZGQyM2UiLCJ1c2VyX3V1aWQiOiJHR0JGVTJKUDUzVUcyTUtGIn0.vbqCrkRT_JqCxxdIEFJMc_qLjPe6Tt551D_Djg_TP4E"
      }
    })
  }

  const fetchNewEventURLs = async (isCancelling) => {
    const eventURL = localStorage.getItem(eventKey)
    const inviteeRes = await handleCalendlyAPI(`${eventURL}/invitees`)
    const inviteeJSON = await inviteeRes.json()

    if (inviteeJSON.collection.length > 0) {
      const setURL = (cancelURL, rescheduleURL) => {
        if (isCancelling) {
          setMeetURL(cancelURL)
          setCancelling(true)
        } else {
          setMeetURL(rescheduleURL)
        }
      }

      const inviteeData = inviteeJSON.collection[0]
      if (inviteeData.rescheduled) {
        const newInviteeRes = await handleCalendlyAPI(inviteeData.new_invitee)
        const newInviteeJSON = await newInviteeRes.json()
        const newInvitee = newInviteeJSON.resource

        setInvitee({
          name: newInvitee.name,
          email: newInvitee.email,
          reschedule_url: newInvitee.reschedule_url,
          cancel_url: newInvitee.cancel_url,
        })
        localStorage.setItem(eventKey, newInvitee.event)
        setURL(newInvitee.cancel_url, newInvitee.reschedule_url)
      } else {
        setURL(invitee.cancel_url, invitee.reschedule_url)
      }

      setScheduled(false)
    }
  }

  const handleScheduled = (eventURL, inviteeData) => {
    setInvitee(inviteeData)
    setScheduled(true)
    localStorage.setItem(eventKey, eventURL)
  }

  const handleReschedule = async () => {
    await fetchNewEventURLs(false)
  }

  const handleCancel = async () => {
    await fetchNewEventURLs(true)
  }

  const handleRefresh = async () => {
    if (cancelling) {
      const eventURL = localStorage.getItem(eventKey)
      const inviteeRes = await handleCalendlyAPI(`${eventURL}/invitees`)
      const inviteeJSON = await inviteeRes.json()

      if (inviteeJSON.collection.length > 0) {
        const inviteeData = inviteeJSON.collection[0]
        if (inviteeData.status === "canceled") {
          setMeetURL(initialMeetURL)
          setInvitee({
            name: '',
            email: '',
            reschedule_url: '',
            cancel_url: '',
          })
          setScheduled(false)
          setCancelling(false)
          localStorage.removeItem(eventKey)
          return
        }
        setScheduled(true)
        setCancelling(false)
      } else {
        localStorage.removeItem(eventKey)
      }
    }

    setScheduled(true)
    setCancelling(false)
  }

  useEffect(() => {
    const eventURL = localStorage.getItem(eventKey)
    if (!eventURL) {
      setLoading(false)
      return
    }


    (async () => {
      const inviteeRes = await handleCalendlyAPI(`${eventURL}/invitees`)
      const inviteeJSON = await inviteeRes.json()
      if (inviteeJSON.collection.length > 0) {
        const inviteeData = inviteeJSON.collection[0]

        // the event is active, display a nice little message
        if (inviteeData.status === "active") {
          setInvitee({
            name: inviteeData.name,
            email: inviteeData.email,
            reschedule_url: inviteeData.reschedule_url,
            cancel_url: inviteeData.cancel_url,
          })
          setScheduled(true)
        }

        // the event was rescheduled, go fetch the new urls
        if (inviteeData.rescheduled) {
          const newInviteeRes = await handleCalendlyAPI(inviteeData.new_invitee)
          const newInviteeJSON = await newInviteeRes.json()
          const newInvitee = newInviteeJSON.resource

          setInvitee({
            name: newInvitee.name,
            email: newInvitee.email,
            reschedule_url: newInvitee.reschedule_url,
            cancel_url: newInvitee.cancel_url,
          })
          localStorage.setItem(eventKey, newInvitee.event)
          setScheduled(true)
        }
        setLoading(false)
      } else {
        // the event is neither active nor rescheduled
        localStorage.removeItem(eventKey)
        setInvitee({
          name: '',
          email: '',
          reschedule_url: '',
          cancel_url: '',
        })
        setScheduled(false)
        setLoading(false)
      }
    })()
  }, [])

  const displayLabel = () => {
    if (cancelling) {
      return (
        <>
          <PageLabel
            title="Cancelling? 😔"
            description="I'm really sorry that you have to cancel this, hope to see you in the near future"
          />
          <Container className='d-flex justify-content-center'>
            <Gopher src={wonderingGopher} alt='Wondering Gopher | Meet Steve Hook 1:1'/>
          </Container>
        </>
      )
    }
    if (scheduled) {
      return (
        <>
          <PageLabel title="Hooray! 🎉">
            <h4 className='animated pulse'>
              I'm really excited and can't wait to meet you <strong>{invitee.name}</strong>
            </h4>
          </PageLabel>
          <Container className='d-flex justify-content-center'>
            <Gopher src={lovingGopher} alt='Loving Gopher | Meet Steve Hook 1:1'/>
          </Container>
        </>
      )
    }

    return (
      <>
        <PageLabel
          title="Let's Meet"
          description={<>Book your first <strong>free</strong> meeting call with me</>}
        />
        <Container className='d-flex justify-content-center'>
          <Gopher
            className='animated fadeIn'
            src={meetingGopher}
            alt='Meeting Gopher | Meet Steve Hook 1:1'
          />
        </Container>
      </>
    )
  }

  const displayMeet = () => {
    return (
      scheduled ? (
        <div className='d-flex justify-content-center'>
          <div className='Meet animated fadeIn slow'>
            <p className='Meet__success'>
              An invitation to this event was already sent to <strong>{invitee.email}</strong>. Make sure to accept
              it and let's see each other soon. In case you've made a mistake or changed your mind, feel free to
              adjust the scheduled event.
            </p>
            <div className='Meet__actions'>
              <Button
                variant="primary"
                className='Meet__actions__button Meet__actions__button--reschedule'
                size="sm"
                onClick={handleReschedule}
              >
                Reschedule
              </Button>
              <Button
                variant="danger"
                className='Meet__actions__button Meet__actions__button--cancel'
                size="sm"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {meetURL === initialMeetURL ? '' : (
            <div className='d-flex justify-content-center'>
              <Button
                title='Refresh'
                variant="success"
                className='Meet__actions__button Meet__actions__button--refresh'
                size="sm"
                onClick={handleRefresh}
              >
                <span className='icon-refresh'/>
              </Button>
            </div>
          )}
          <Meet
            url={meetURL}
            onScheduled={handleScheduled}
            onAPICall={handleCalendlyAPI}
            isScheduled={meetURL !== initialMeetURL}
          />
        </>
      )
    )
  }

  const isBrowser = () => typeof window !== "undefined"

  return (
    <Layout
      title='Meet'
      description='Steve Hook 1:1 Go (Golang) Mentorship. Schedule your first meeting call with Steve Hook'
      thumbnail='meet.png'
      pageURL='/meet'
    >
      <Container className='Meet__container'>
        <Loader loading={loading}/>
        {!isBrowser() || (localStorage.getItem(eventKey) && !invitee) ? '' : displayLabel()}
        {!isBrowser() || (localStorage.getItem(eventKey) && !invitee) ? '' : displayMeet()}
      </Container>
    </Layout>
  )
}

export default MeetPage
